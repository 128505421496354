import React from 'react';
import DropDown from './dropdown';
import DropDownSVG from '../assets/svg/dropdown.svg';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProfileMenu = () => {
  const history = useHistory();
  const { user } = useSelector((state) => state.userSlice);

  const menus = [
    // {
    //   name: 'Dashboard',
    //   route: '/dashboard',
    // },
    {
      name: 'Logout',
      route: '/logout',
    },
  ];

  return (
    <div className='flex flex-row items-center'>
      {/* Profile Image Placeholder */}
      <div className='bg-secondary-2-light h-[40px] w-[40px] rounded-[10px]'></div>
      <div className='ml-2 flex flex-col'>
        <DropDown
          toggler={
            <div className='d-flex flex-row'>
              <div className='flex flex-col text-start'>
                <span className='p2-medium'>
                  {' '}
                  {user.first_name + ' ' + user.last_name}{' '}
                </span>
                <span className='text-color-gray text-[12px] leading-[12px]'>
                  Admin
                </span>
              </div>
              <img src={DropDownSVG} alt='' className='ml-2' />
            </div>
          }
        >
          {menus.map((item) => (
            <button
              className='w-full text-start py-[10px] px-[12px] text-[0.95em] bg-color-white cursor-pointer hover:bg-color-off-white outline-none'
              onClick={() => {
                history.push(item.route);
              }}
            >
              {item.name}
            </button>
          ))}
        </DropDown>
        <div></div>
      </div>
    </div>
  );
};

export default ProfileMenu;
