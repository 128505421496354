import { Axios } from '../axios';

const getEmployees = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter =
    payload.statusFilter !== -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(
    `/employees?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
  );

  return data?.data;
};

const updateEmployee = async (payload) => {
  const { data } = await Axios.put(
    '/employees/' + payload.employeeId,
    payload.data,
  );
  return data;
};

const updateInvitedEmployee = async (payload) => {
  const { data } = await Axios.put(
    '/employee/update-employee/' + payload.employeeId,
    payload.data,
  );
  return data;
};

const getEmployee = async (payload) => {
  const { data } = await Axios.get('/employees/' + payload);
  return data?.data;
};

const getEmployeeSummary = async () => {
  const { data } = await Axios.get('/employees/summary');
  return data?.data;
};


const inviteSingleEmployee = async (payload) => {
  const { data } = await Axios.post('/employees/invite', payload);
  return data;
};

const inviteBulkEmployee = async (payload) => {
  const { data } = await Axios.post('employees/inivite/bulk', payload);
  return data;
};

const createSingleEmployee = async (payload) => {
  const { data } = await Axios.post('/employees', payload);
  return data;
};

const createBulkEmployee = async (payload) => {
  const { data } = await Axios.post('/employees/bulk', payload);
  return data;
};

const addEmployeeGroup = async (payload) => {
  const { data } = await Axios.post('/employee-groups', payload);
  return data;
};

const netPayReverse = async (payload) => {
  const { data } = await Axios.post('/salaries/net-pay-reverse', payload);
  return data;
};

const netPayReverseBulk = async (payload) => {
  const { data } = await Axios.post('/salaries/net-pay-reverse/bulk', payload);
  return data;
};

const grossPayReverse = async (payload) => {
  const { data } = await Axios.post('/salaries/gross-pay-reverse', payload);
  return data;
};

const grossPayReverseBulk = async (payload) => {
  const { data } = await Axios.post(
    '/salaries/gross-pay-reverse/bulk',
    payload,
  );
  return data;
};

const bulkActivateEmployees = async (payload) => {
  const computedPayload = {
    employee_ids: payload,
  };

  const { data } = await Axios.post(
    '/employees/bulk-activation/activate',
    computedPayload,
  );
  return data;
};

const activateOrDeactivateEmployee = async (payload) => {
  const { data } = await Axios.put('/employees/activation/' + payload);
  return data;
};

const bulkDeactivateEmployees = async (payload) => {
  const computedPayload = {
    employee_ids: payload,
  };

  const { data } = await Axios.post(
    '/employees/bulk-activation/deactivate',
    computedPayload,
  );
  return data;
};

const generateEmployeeOtp = async (payload) => {
  const { data } = await Axios.post('/employee/generate-pin',  payload);
  return data;
};

const confirmEmployeeOtp = async (payload) => {
  const { data } = await Axios.post('/employee/confirm-pin', payload);
  return data;
};

export const employees = {
  getEmployees,
  updateEmployee,
  getEmployeeSummary,
  getEmployee,
  inviteSingleEmployee,
  createBulkEmployee,
  inviteBulkEmployee,
  createSingleEmployee,
  updateInvitedEmployee,
  addEmployeeGroup,
  netPayReverse,
  netPayReverseBulk,
  grossPayReverse,
  grossPayReverseBulk,
  bulkActivateEmployees,
  activateOrDeactivateEmployee,
  bulkDeactivateEmployees,
  generateEmployeeOtp,
  confirmEmployeeOtp
};
