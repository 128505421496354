import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import FormInput from '../../components/form-input';
import Button from '../../components/button';
import { formValidation } from '../../utils/functions';
import AuthLayout from '../../components/layouts/auth';
import { useLogin } from '../../redux/admin/hook';

const SignInPage = () => {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const { user } = useSelector((state) => state.userSlice);

  const { mutate: signIn, isLoading: loading } = useLogin();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const submitForm = async (data) => {
    const email = DOMPurify.sanitize(data?.email);
    const password = DOMPurify.sanitize(data?.password);

    const requestData = {
      email,
      username: email,
      password,
    };

    signIn(requestData);
  };

  // if (user && section) {
  //   window.location.reload();
  // }

  return (
    <AuthLayout>
      <React.Fragment>
        <form className='form' onSubmit={handleSubmit(submitForm)}>
          <div className='w-full text-center flex flex-col pb-[25px]'>
            <span className='header-3-regular md:header-2 text-secondary-2'>
              Welcome back <span className='header-4'> Admin</span>
            </span>
          </div>

          <FormInput
            label='Email address'
            name='email'
            type='text'
            inputRef={register(formValidation('email', true))}
            readOnly={loading}
            error={errors.email}
            errorMessage={errors.email && errors.email.message}
          />

          <FormInput
            label='Password'
            name='password'
            type='password'
            inputRef={register(formValidation('password', true))}
            readOnly={loading}
            error={errors.password}
            errorMessage={errors.password && errors.password.message}
          />

          <div className='flex justify-between pb-[5px] pt-[5px]'>
            <FormInput
              label='Remember me'
              name='remember'
              type='checkbox'
              inputRef={register(formValidation('checkbox'))}
              readOnly={loading}
              error={errors.remember}
              errorMessage={errors.remember && errors.remember.message}
            />

            <p className='forgot_password'>
              <Link to='/forgot'>Forgot Password?</Link>
            </p>
          </div>

          <Button
            text='Sign in'
            type='submit'
            loading={loading}
            disabled={!isValid}
          />
        </form>
      </React.Fragment>
    </AuthLayout>
  );
};

export default SignInPage;
