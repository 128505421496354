import { Axios } from './axios';

const getCompany = async (payload) => {
  const { data } = await Axios.get(`/companies`);
  return data;
};

const getCompanies = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let start_date = payload.start_date ? '&start_date=' + payload.start_date : '';
  let end_date = payload.end_date ? '&end_date=' + payload.end_date : '';
  const { data } = await Axios.get(`/companies?limit=${payload.limit}&page=${payload.page}${start_date}${end_date}${search}`,
  {
    timeout: 0
  });
  return data?.data;
};

const chargeCompany = async (payload) => {
  const { data } = await Axios.get(`/companies/${payload}/debit-charge`);
  return data;
};

const refundCompanies = async (payload) => {
  const { data } = await Axios.get(`/refunds/pay/all`);
  return data;
};

const payPensions = async (payload) => {
  const { data } = await Axios.get(`/pensions/pay/all`);
  return data;
};

const payTaxes = async (payload) => {
  const { data } = await Axios.get(`/taxes/make-taxpal-payments`);
  return data;
};

export const companies = {
  getCompany,
  getCompanies,
  chargeCompany,
  refundCompanies,
  payPensions,
  payTaxes
};
