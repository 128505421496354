import { Axios } from './axios';

const getWalletHistory = async (payload) => {
  let start_date =
    payload.start_date ? '&start_date=' + payload.start_date : '';
  let end_date = payload.end_date ? '&end_date=' + payload.end_date : '';

  const { data } = await Axios.get(
    `/wallet/history?limit=${payload.limit}&page=${payload.page}${start_date}${end_date}`,
  );

  return data?.data;
};

const getMobileWalletHistory = async (payload) => {
  const { data } = await Axios.get(
    `/transactions/mobile/history?limit=${payload.limit}&page=${payload.page}`,
  );

  return data?.data;
};



const exportAccountStatement = async (payload) => {
  const { data } = await Axios.post('/bonuses/bulk', payload);
  return data;
};


export const walletInformation = {
  getWalletHistory,
  getMobileWalletHistory,
  exportAccountStatement,
};
