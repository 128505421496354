import React from 'react';
import Loader from './loader';
import Icon from '../assets/svg';

const Button = ({
  className,
  textClass,
  text,
  theme = 'primary',
  leftIcon,
  rightIcon,
  loading,
  disabled,
  onClick,
  ...rest
}) => {
  let btnClass =
    theme === 'secondary'
      ? '!bg-color-white h-[42px] rounded-md border-secondary-2 border-[1.5px] flex items-center justify-center uppercase'
      : '!bg-secondary-2 rounded-[5px] h-[42px] flex justify-center items-center uppercase';

  return (
    <button
      className={`w-full shadow-md ${btnClass} ${className} ${loading && 'loading'}`}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {loading ? (
        <Loader color={className === 'btn_secondary' && 'white'} />
      ) : (
        <>
          {leftIcon && (
            <span className='left_icon'>
              <Icon name={leftIcon} />
            </span>
          )}
          <span
            className={`${
              theme === 'secondary' ? 'text-secondary-2' : 'text-white'
            } text-[14px] leading-[23.4px] font-bold uppercase ${textClass}`}
          >
            {text}
          </span>
          {rightIcon && (
            <span className='right_icon'>
              <Icon name={rightIcon} />
            </span>
          )}
        </>
      )}
    </button>
  );
};

export default Button;
