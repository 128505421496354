// Local storage operations
export const useLocalStorage = {
  set: (key, data) => {
    let stringifiedData = JSON.stringify(data);
    localStorage.setItem(key, stringifiedData);
  },

  get: (key) => {
    const data = JSON.parse(localStorage.getItem(key));

    if (!data) {
      return null;
    }
    return data;
  },

  remove: (key) => {
    localStorage.removeItem(key);
  },

  clear: () => {
    localStorage.clear();
  },
};

export const Initials = (first = null, second = null) => {
  if (!first && !second) {
    return '';
  } else if (first && !second) {
    return first[0];
  } else if (first && second) {
    return first[0] + second[0];
  }
};

export const getRequestError = (error) => {
  const { response } = error;
  if (
    response &&
    (response?.data?.status?.code === 115 || response?.status === 401)
  ) {
    return response?.data?.status?.desc;
  } else if (response && response?.data?.status === 400) {
    logout();
  } else if (response && response?.data?.errors) {
    if (typeof response?.data?.errors === 'object') {
      return Object.values(response?.data?.errors)?.[0];
    } else {
      return response?.data?.errors;
    }
  } else if (response && response?.data?.message) {
    return response?.data?.message;
  } else if (response && response?.data?.error) {
    return response?.data?.error;
  }
  return 'There might be a problem with your internet connection. Please check and try again.';
};

export const logout = () => {
  useLocalStorage.remove('app_user');
  window.scrollTo(0, 0);
  window.location.reload();
};

export const formatDateInWords = (date) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const current_date = new Date(date);
  const month_value = current_date.getMonth();
  const day_value = current_date.getDate();
  const year = current_date.getUTCFullYear();
  let hour = current_date.getHours();
  let mins = current_date.getMinutes();

  if (hour < 10) hour = `0${hour}`;
  if (mins < 10) mins = `0${mins}`;

  return date
    ? `${day_value} ${months[month_value]}, ${year} | ${hour}:${mins}`
    : '';
};

export const formatInputDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year, month, day].join('-');
};

export const formatAmount = (amount, decimals) => {
  return amount
    ? `N` +
        Number(amount)
          .toFixed(decimals || 2)
          .replace(/./g, function (c, i, a) {
            return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c;
          })
    : `N--`;
};

export const formatNumber = (n, decimals) => {
  return (
    n &&
    Number(n)
      .toFixed(decimals || 0)
      .replace(/./g, function (c, i, a) {
        return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c;
      })
  );
};

export const firstLetter = (letter) => {
  var str = letter;
  var res = str.substring(0, 1);
  return res;
};

export const statusText = (status) => {
  switch (status) {
    case 1:
      return 'Active';
    case 2:
      return 'Inactive';
    case 3:
      return 'Disabled';
    default:
      return 'Unknown';
  }
};

export const statusStyling = (status) => {
  switch (status) {
    case 'active':
      return 'success';
    case 'successful':
      return 'success';
    case 'processing':
      return 'success';
    case 'completed':
      return 'secondary';
    case 'pending':
      return 'warning';
    case 'inactive':
      return 'tertiary';
    case 'unsuccessful':
      return 'error';
    default:
      return 'success';
  }
};

export const sumOfObjectKeys = (array, key) => {
  return array.reduce((a, b) => a + (b[key] || 0), 0);
};

export const formValidation = (type, required, min, max) => {
  if (type === 'email') {
    return {
      required: required ? 'Email address is required' : false,
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Invalid email address',
      },
    };
  } else if (type === 'text') {
    return {
      required: required ? 'This field is required' : false,
      minLength: min
        ? {
            value: min + 1,
            message: `Must be more than ${min} character(s)`,
          }
        : {
            value: 2,
            message: 'Must be more than 1 character',
          },
    };
  } else if (type === 'non-empty') {
    return {
      required: required ? 'This field cannot be empty' : false,
    };
  } else if (type === 'number') {
    return {
      required: required ? 'This field is required' : false,
      min: min
        ? {
            value: min,
            message: `Minimum value is ${min}`,
          }
        : null,
      max: max
        ? {
            value: max,
            message: `Maximum value is ${max}`,
          }
        : null,
    };
  } else if (type === 'password') {
    return {
      required: required ? 'This field is required' : false,
      minLength: {
        value: 8,
        message: 'Must be atleast 8 characters',
      },
    };
  } else if (type === 'file') {
    return {
      required: required ? 'This field is required' : false,
    };
  } else if (type === 'select') {
    return {
      required: required ? 'This field is required' : false,
    };
  } else if (type === 'radio') {
    return {
      required: required ? 'This field is required' : false,
    };
  } else if (type === 'checkbox') {
    return {
      required: required ? 'This field is required' : false,
    };
  } else if (type === 'date') {
    return {
      required: required ? 'This field is required' : false,
    };
  }
};
