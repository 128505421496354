import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { bounceInUp } from 'react-animations';
import Radium, { StyleRoot } from 'radium';

import { closeAlert } from '../redux/components/components-slice';

const Alert = ({
  className,
  textBeforeLink,
  linkText,
  linkTo,
  textAfterLink,
  children,
  close,
  closeAlertAction,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      dispatch(closeAlert());
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const styles = {
    bounceInUp: {
      animation: 'x .6s',
      animationName: Radium.keyframes(bounceInUp, 'bounceInUp'),
    },
  };

  return (
    <StyleRoot>
      <div className={`alert_container ${className}`} style={styles.bounceInUp}>
        <div className='alert'>
          <p>
            {textBeforeLink}
            {linkTo && <Link to={linkTo}>{linkText}</Link>}
            {textAfterLink}
          </p>
          {children}
        </div>
        {close && (
          <div
            className='close_div'
            onClick={() =>
              closeAlertAction ? closeAlertAction : dispatch(closeAlert())
            }
          >
            <img
              className='close'
              src='https://res.cloudinary.com/the-now-entity/image/upload/q_auto/v1610444093/Lendha/x_icon_ihn2xz.svg'
              alt=''
            />
          </div>
        )}
      </div>
    </StyleRoot>
  );
};

export default Alert;
