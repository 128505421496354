const { Axios } = require('./axios');


const login = async (payload) => {
  const { data } = await Axios.post('/login', payload);
  return data;
};

const profile = async () => {
  const { data } = await Axios.get('/me');
  return data;
};

const getAdmins = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  // let filter = payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  const { data } = await Axios.get(`/admins?limit=${payload.limit}&page=${payload.page}${search}`);
  return data?.data;
};

const getAdmin = async (payload) => {
  const { data } = await Axios.get('/admins/' + payload );
  return data;
};

const addAdmin = async (payload) => {
  const { data } = await Axios.post(
    '/admins', payload
  );
  return data;
};

const updateAdmin = async (payload) => {
  const { data } = await Axios.put(
    '/admins/' + payload.id, payload.data
  );
  return data;
};

const removeAdmin = async (payload) => {
  const { data } = await Axios.delete(
    '/admins/' + payload,
  );
  return data;
};

const activateAdmin = async (payload) => {
  const { data } = await Axios.get('/admins/activation/' + payload);
  return data;
};

const resendActivationLiink = async (payload) => {
  const { data } = await Axios.get(
    '/admins/resend-link/' + payload,
  );
  return data;
};

const adminPasswordSet = async (payload) => {
  const { data } = await Axios.post('/activate', payload);
  return data;
};

const forgotPassword = async (payload) => {
  const { data } = await Axios.get(`/forgot-password/${payload}`);
  return data;
};

const passwordReset = async (payload) => {
  const { data } = await Axios.post('/reset-password', payload);
  return data;
};

const fetchAdminByToken = async (payload) => {
  const { data } = await Axios.get('/fetch-by-token/' + payload);
  return data;
};

export const admin = {
  login,
  profile,
  getAdmins,
  getAdmin,
  addAdmin,
  updateAdmin,
  removeAdmin,
  activateAdmin,
  resendActivationLiink,
  adminPasswordSet,
  forgotPassword,
  passwordReset,
  fetchAdminByToken,
};
