import React from 'react';
import Logo from './logo';
import { NavLink } from 'react-router-dom';
import HomeSVG from '../assets/svg/sidebar/home.svg';
import EmployeeSVG from '../assets/svg/sidebar/employees.svg';
import PayrollSVG from '../assets/svg/sidebar/payroll.svg';
import PaymentSVG from '../assets/svg/sidebar/payments.svg';
import WalletSVG from '../assets/svg/sidebar/wallet.svg';
import DocumentSVG from '../assets/svg/sidebar/documents.svg';
import SettingsSVG from '../assets/svg/sidebar/settings.svg';
import RecruitmentSVG from '../assets/svg/sidebar/recruitment.svg';

const Sidebar = () => {
  const sidebarItems =  [
    {
      name: 'Dashboard',
      icon: HomeSVG,
      route: '/dashboard',
      active: true
    },
    {
      name: 'Users',
      icon: EmployeeSVG,
      route: '/users',
    },
    {
      name: 'Mobile Users',
      icon: EmployeeSVG,
      route: '/mobile-users',
    },
    {
      name: 'Companies',
      icon: DocumentSVG,
      route: '/companies',
    },
    {
      name: 'Recruitments',
      icon: RecruitmentSVG,
      route: '/recruitments',
    },
    {
      name: 'Employees',
      icon: EmployeeSVG,
      route: '/employees',
    },
    {
      name: 'Transactions',
      icon: WalletSVG,
      route: '/transactions',
    },
    {
      name: 'Mobile Transactions',
      icon: WalletSVG,
      route: '/mobile-transactions',
    },
    {
      name: 'Payroll',
      icon: PayrollSVG,
      route: '/payroll',
    },
    {
      name: 'Statutory Payments',
      icon: PaymentSVG,
      route: '/statutory-compliance',
    },
    {
      name: 'Tickets',
      icon: PaymentSVG,
      route: '/tickets',
    },
    {
      name: 'Settings',
      icon: SettingsSVG,
      route: '/settings',
    },
  ]

  return (
    <div className='w-full h-full'>
      <div className='flex px-[15px] pt-[20px]'>
        <Logo width={'150px'} height={'75px'} />
      </div>

      <div className='flex flex-col'>
        <>
          {sidebarItems.map((item, index) => (
            <NavLink
              to={item.route}
              className='text-color-gray font-normal text-[13px] leading-[15px] flex pl-[30px] h-[40px] items-center '
              activeClassName='font-bold text-secondary-2 font-normal bg-[#f2f3f9] h-[40px] border-r-secondary-2 border-r-[5px]'
            >
              <img src={item.icon} alt={item.name} className={'pr-[15px]'} />
              {item.name}
            </NavLink>
          ))}
        </>
      </div>
    </div>
  );
};

export default Sidebar;
