import React from 'react';
import Logo from '../logo';

const AuthLayout = ({ children }) => {
  return (
    <div className='flex flex-row' >
      <div className='hidden md:flex md:w-[45%] position-fixed'>
        <div
          className='hidden md:flex h-screen inset-0 w-full radial-gradient justify-center items-center max-h-screen'
    
        >
          <div className='flex flex-col w-[80%] max-w-[400px]'>
            <div className='mb-[30px] flex justify-start'>
              <Logo white />
            </div>
            <h1 className='text-color-white text-[0.9em] leading-[150%] font-normal text-left'>
              Payroll and Spend management software for organizations of all
              sizes. Run Payroll <br />seamlessly whether you are a company,
              multiple companies or an outsourcing firm.
            </h1>
          </div>
        </div>
      </div>

      {/* Page Components */}
      <div
        className='md:w-[100%] w-[100%] flex items-center 
         justify-center
         min-h-screen
         md:ml-[45%]
         pb-4
         pt-4'
      >
        <div className='w-[78%] max-w-[480px]'>
          <div className='flex flex-col'>
            <div className='md:hidden flex justify-center'>
              <Logo width={'170px'} height={'76px'} />
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
